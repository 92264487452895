import axios from '@/utils/request'
let Api = {
	manhourList: "/workingHours/list",
	manhourDetail: "/post/",
	manhourDownload: "/post/export.xlsx",
	workingScheduleUpload: "/post/workingScheduleUpload",
	manhourUpdateAll: "/workingHours/updateWorkingHourBatch",
	manhourTotal: "/workingHours/student/summary",
	manhourConfirmAll: "/workingHours/confirmAllWorkingHour",
}
// 工时列表
export function manhourListMb(params) {
	return axios({
		method: 'get',
		url: Api.manhourList,
		params: params
	})
}
// 工时详情
export function manhourDetailMb(id, params) {
	return axios({
		method: 'get',
		url: Api.manhourDetail + id,
		params: params
	})
}
// 上传排班表
export function workingScheduleUploadMb(params) {
	return axios({
		method: 'post',
		url: Api.workingScheduleUpload,
		data: params
	})
}
// 工时数据导出
export function manhourDownloadMb(params) {
	return axios({
		method: 'get',
		url: Api.manhourDownload,
		params: params,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'application/json;charset=UTF-8'
		},
		withCredentials: true,
		observe: 'response',
		responseType: 'arraybuffer',
	})
}
// 工时编辑
export function manhourUpdateAllMb(params) {
	return axios({
		method: 'put',
		url: Api.manhourUpdateAll,
		data: params
	})
}
//岗位总工时统计
export function manhourTotalMb(params) {
	return axios({
		method: 'get',
		url: Api.manhourTotal,
		params: params
	})
}
// 工时一键确认
export function manhourConfirmAllMb(params) {
	return axios({
		method: 'put',
		url: Api.manhourConfirmAll,
		params: params
	})
}
