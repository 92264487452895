<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2022-07-28 14:24:07
-->
<template>
	<div>
		<van-pull-refresh class="activity" v-model="fresh" @refresh="onRefresh">
			<van-list offset="10" v-model="loadMore" :finished="loadFinish"
				:finished-text="jobData.length >= 5 ?'没有更多了':''" @load="scrollToLower">
				<div class="activity-tab">
					<div @click="tabFn(item)" v-for="(item, index) in tabList" :key="index" class="activity-tab-item">
						<div class="tab-item-left">
							<span>{{item.num}}</span>
							<span>{{item.title}}</span>
						</div>
						<img class="tab-item-right" :src="item.url" alt="">
					</div>
				</div>
				<div class="activity-list" id="app">
					<div class="activity-list-search">
						<div class="list-search-left">
							岗位列表
						</div>
						<div class="activity-list-right-flex">
							<div @click="showTypeSearch('school')" class="list-search-right" style="margin-right:0.32rem">
								<span>校区筛选</span>
								<img src="@/assets/images/mobile/student/icon3.png" alt="">
								<div v-if="isShowSchool" class="list-search-list">
									<span :class="{'search-list-active': item.id == currentSchoolType}"
										v-for="(item, index) in schoolList" :key="index"
										@click.stop="handleSearch(item,'school')">{{item.name}}</span>
								</div>
							</div>
							<div @click="showTypeSearch('post')" class="list-search-right">
								<span>岗位筛选</span>
								<img src="@/assets/images/mobile/student/icon3.png" alt="">
								<div v-if="isShowType" class="list-search-list">
									<span :class="{'search-list-active': item.type == currentType}"
										v-for="(item, index) in searchList" :key="index"
										@click.stop="handleSearch(item,'post')">{{item.title}}</span>
								</div>
							</div>
						</div>
						<!-- <div @click="showTypeSearch" class="list-search-right">
							<span>岗位筛选</span>
							<img src="@/assets/images/mobile/student/icon3.png" alt="">
							<div v-if="isShowType" class="list-search-list">
								<span :class="{'search-list-active': item.type == currentType}"
									v-for="(item, index) in searchList" :key="index"
									@click.stop="handleSearch(item)">{{item.title}}</span>
							</div>
						</div> -->
					</div>
					<!-- <div class="activity-list-content">
						<van-pull-refresh v-model="fresh" @refresh="onRefresh">
							<van-list offset="30" v-if="jobData.length" v-model="loadMore" :finished="loadFinish" finished-text="没有更多了"
								@load="scrollToLower">
								<jobList :importArea="'S'" :jobList="jobData" />
							</van-list>
						</van-pull-refresh>
					</div> -->
				</div>
				<jobList :importArea="'student'" :jobList="jobData" :showEmpty="showEmpty" />
			</van-list>
		</van-pull-refresh>
		<tooltip @confirm="confirm" :confirmText="'立即前往'" :showCancle="false" :isShowToolTip="isShowToolTip" :tooltipTitle="'温馨提示'"
			:tooltipContent="'你好同学，你有新的岗位工时待确认'" />
	</div>
</template>
<script>
	import jobList from "../components/jobList.vue";
	import {
		jobListMb,
		jobCalculate
	} from "@/api/mobile/activity";
	import {
		manhourTotalMb,
		manhourConfirmAllMb,
	} from "@/api/mobile/manhour";
		import {
		schoollistAllPc
	} from '@/api/pc/index'
	import {
		mapActions
	} from 'vuex'
	import lib from '@/utils/common.js';
	export default {
		components: {
			jobList
		},
		// inject:['reload'],
		data() {
			return {
				schoolList: [],
				searchList: [
					{
						type: 0,
						title: "学院固定",
						value: "C_FIX"
					}, {
						type: 1,
						title: "校内固定",
						value: "S_FIX"
					},
					{
						type: 2,
						title: "校内临时",
						value: "S_TEMP"
					},
					{
						type: 3,
						title: "校外家教",
						value: "SO_FIX_TUTOR"
					},
					{
						type: 4,
						title: "校外兼职",
						value: "SO_PART_TIME"
					},
				],
				currentType: -1,
				currentSchoolType:-1,
				isShowType: false,
				isShowSchool:false,
				tabList: [{
						num: 0,
						title: "已报名岗位",
						url: require("@/assets/images/mobile/student/icon1.png")
					},
					{
						num: 0,
						title: "岗位总工时",
						url: require("@/assets/images/mobile/student/icon2.png")
					},
				],
				jobData: [],
				pageIndex: 1,
				pageSize: 6,
				totalCount: 0,
				fresh: false,
				loadMore: false,
				loadFinish: false,
				showEmpty: false,
				postType: "",
				schoolId:"",
				signCalculate:"",
				isShowToolTip: false,
				code:"",
				workingHoursProcesses:[],
			}
		},
		// beforeRouteEnter(to,from,next){
		// 	console.log(from,next)
		// 	to.meta.keepAlive = true
		// 	if(from.path!=='/mb/activity/detail'){
		// 		next(vm=>{
		// 			vm.reload()
		// 		})
		// 	}else{
		// 		next()
		// 	}
		// },
		
		mounted(){
			// console.log('avtivitytytytyty123123')
			// console.log(this.$route.query,'123123123123')
			storage.get("code",this.$route.query.code)
		},
		methods: {
			//获取校区列表
			getSchoolList() {
				let params = {
					pageIndex: 1,
					pageSize: 999
				}
				schoollistAllPc(params).then((res) => {
					if (res.code == 0) {
						this.schoolList = res.data
					}
				})
			},
			//已报名岗位统计
			handleCalculate(){
				jobCalculate().then(res=>{
					if(res.code == 0){
						this.tabList[0].num = res.data
					}
				})
			},
			// 岗位总工时统计
			getManhourTotal() {
				manhourTotalMb().then((res) => {
					if (res.code == 0) {
						this.tabList[1].num = res.data.totalWorkingHour
						if(res.data.workingHoursProcesses.length > 0) {
							this.isShowToolTip = true
							this.workingHoursProcesses = res.data.workingHoursProcesses
						} else {
							this.isShowToolTip = false
						}
					}
				})
			},
			confirm() {
				this.isShowToolTip = false
				var id = this.workingHoursProcesses[0].postId
				this.$router.push({
					path: `/mb/activity/detail?id=${id}`
				})
				// manhourConfirmAllMb().then((res) => {
				// 	if (res.code == 0) {
						
				// 		this.$router.push({
				// 			path: `/mb/activity/jobhour?visitType=confirm`
				// 		})
				// 		this.isShowToolTip = false
				// 	}
				// })
			},
			//点击筛选
			handleSearch(item,type) {
				this.pageIndex = 1
				this.jobData = []
				if(type==='post'){
					if (this.postType == item.value) {
						this.postType = ""
						this.currentType = -1
					} else {
						this.postType = item.value
						this.currentType = item.type
					}
				}else{
					if (this.schoolId == item.id) {
						this.schoolId= ""
						this.currentSchoolType = -1
					} else {
						this.schoolId = item.id
						this.currentSchoolType = item.id
					}
				}
				
				this.getJobList()
			},
			// 获取活动报名列表
			getJobList() {
				return new Promise((resolve, reject) => {
					this.loadFinish = false
					this.showEmpty = false
					let params = {
						pageIndex: this.pageIndex,
						pageSize: this.pageSize,
						postType: this.postType,
						schoolId:this.schoolId,
						state:1,
					}
					jobListMb(params).then((res) => {
						console.log("getJobList", res)
						if (res.code == 0) {
							if (this.pageIndex == 1) {
								this.fresh = false;
							}
							this.loadMore = false
							res.data.forEach(item => {
								this.$set(item, "status", 1)
							})
							this.jobData = this.jobData.concat(res.data)
							this.totalCount = res.page.totalCount
							if (this.jobData.length >= res.page.totalCount * 1) {
								this.loadFinish = true
							}
							if (this.jobData.length == 0) {
								this.showEmpty = true
							}
							this.pageIndex++
							resolve()
						} else {
							this.loadMore = false
							this.loadFinish = true
							this.fresh = false
							this.showEmpty = false
							reject()
						}
					}).catch((err) => {
						this.loadMore = false
						this.loadFinish = true
						this.fresh = false
						this.showEmpty = false
						reject()
					})
				})
			},
			onRefresh() {
				this.pageIndex = 1
				setTimeout(() => {
					this.jobData = []
					this.getJobList()
				}, 1000);
			},
			scrollToLower() {
				// 加载状态结束
				if(this.totalCount > this.pageSize&&this.pageIndex > 1) {
					this.getJobList()
				}
			},
			showTypeSearch(type) {
				if(type==='school'){
					this.isShowSchool = !this.isShowSchool
				}else{
					this.isShowType = !this.isShowType
				}
			},
			tabFn(item) {
				switch (item.title) {
					case "已报名岗位":
						this.$router.push({
							path: `/mb/activity/applyed?visitType=applyed`
						})
						break;
					case "岗位总工时":
						this.$router.push({
							path: `/mb/activity/jobhour`
						})
						break;
				}
			}
		},
		mounted() {
			this.getJobList()
			this.handleCalculate()
			this.getManhourTotal()
			this.getSchoolList()
			if (this.$dd.env.platform !== "notInDingTalk") {
				this.$dd.ready(() => {
					// 设置右上角按钮
					// this.$dd.biz.navigation.setRight({
					// 	show: true, //控制按钮显示， true 显示， false 隐藏， 默认true
					// 	control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
					// 	text: '发送', //控制显示文本，空字符串表示显示默认文本
					// 	onSuccess: function(res) {
					// 		console.log("钉钉H5设置右上角标题成功", res)
					// 	},
					// 	onFail: function(err) {
					// 		console.log("钉钉H5设置右上角标题失败", err)
					// 	}
					// });
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.activity {
		height: 100%;
		background-color: #F7F8F9;
		display: flex;
		flex-direction: column;
		// padding: 0.28rem 0.28rem;
		// box-sizing: border-box;

		.activity-tab {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.28rem 0.28rem 0 0.28rem;
			box-sizing: border-box;

			.activity-tab-item {
				width: 3.34rem;
				background: #FFFFFF;
				border: 0.04rem solid #FFFFFF;
				box-shadow: 0rem 0.04rem 0.16rem rgba(220, 223, 230, 0.2000);
				border-radius: 0.12rem;
				padding: 0.28rem 0.32rem;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.tab-item-left {
					display: flex;
					flex-direction: column;
				}

				.tab-item-left :nth-child(1) {
					font-size: 0.48rem;
					font-family: DIN Alternate;
					font-weight: bold;
					line-height: 0.56rem;
					color: #303133;
				}

				.tab-item-left :nth-child(2) {
					font-size: 0.24rem;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 0.34rem;
					color: #909399;
				}

				.tab-item-right {
					width: 1.06rem;
					height: 1.06rem;
				}
			}
		}

		.activity-list {
			flex: 1;
			display: flex;
			flex-direction: column;
			padding: 0 0.28rem;
			box-sizing: border-box;

			.activity-list-search {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-left: 0.06rem solid #4392FA;
				padding-left: 0.16rem;
				margin: 0.28rem 0 0.2rem 0;

				.list-search-left {
					font-size: 0.32rem;
					font-family: PingFang SC;
					font-weight: bold;
					line-height: 0.44rem;
					color: #303133;
				}
				.activity-list-right-flex{
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}
				.list-search-right {
					display: flex;
					align-items: center;
					position: relative;

					span {
						font-size: 0.26rem;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0.44rem;
						color: #909399;
						margin-right: 0.08rem;
					}

					img {
						width: 0.24rem;
						height: 0.24rem;
					}

					.list-search-list {
						position: absolute;
						top: 0.5rem;
						right: 0rem;
						display: flex;
						flex-direction: column;
						width: 1.76rem;
						background: #FFFFFF;
						box-shadow: 0rem 0.04rem 0.08rem #DCDFE6;
						border-radius: 0.08rem;

						span {
							width: 1.76rem;
							font-size: 0.26rem;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 0.36rem;
							color: #303133;
							border-bottom: 1px solid #DCDFE6;
							padding: 0.16rem 0.16rem;
							box-sizing: border-box;
						}

						.search-list-active {
							color: #4392FA;
						}
					}

					.list-search-list :last-child {
						border: 0;
					}
				}
			}

			.activity-list-content {}
		}
	}
</style>
